<template>
  <div class="home pd60">
    <!-- <router-link to="/test" class="test">测试登录页面</router-link>
    <router-link to="/login" class="test">登录</router-link> -->
    <div class="top">
      <img src="@/assets/banner.png" />
      <div class="menus fz12 pt20">
        <a-row :gutter="[30, 30]">
          <a-col :span="6" @click="sendTo('/order', '2')">
            <a-badge style="margin-top: 1px" :count="forHandleNumber"
              ><i class="iconfont">
                <img src="@/assets/dcl.svg" />
              </i>
            </a-badge>
            <div><span>待处理</span></div>
          </a-col>
          <a-col :span="6" @click="sendTo('/order', '3')"
            ><i class="iconfont">
              <img src="@/assets/ycl.svg" />
            </i>
            <div><span>已处理</span></div></a-col
          >
          <a-col :span="6" @click="sendTo('/order', '1')"
            ><i class="iconfont">
              <img src="@/assets/ytj.svg" />
            </i>
            <div><span>已提交</span></div></a-col
          >
          <a-col :span="6" @click="sendTo('/order', '4')"
            ><i class="iconfont">
              <img src="@/assets/cgx.svg" />
            </i>
            <div><span>待提交</span></div></a-col
          >
        </a-row>
      </div>
      <div class="home-btn-box">
        <a-button
          type="primary"
          class="reprot-btn fz18"
          @click="sendTo('/report')"
          >我要上报</a-button
        >
      </div>
    </div>
    <div class="main">
      <div class="main-content bg-block mt23">
        <div class="content-title border-bottom">
          <span class="fz14">待处理</span>
          <a
            href="javascript:;"
            @click="sendTo('/order', '2')"
            class="ft-grey fz12"
            >更多</a
          >
        </div>
        <ul class="wait-list" v-if="pendingList.length > 0">
          <li
            class="pb15"
            v-for="item in pendingList"
            :key="item.id"
            @click="$router.push(`/detail?id=${item.id}`)"
          >
            <div class="content-block">
              <div class="user-info flex-between mt15">
                <div class="info-left flex-start">
                  <a-avatar
                    :size="40"
                    icon="user"
                    class="mr10"
                    :src="item.reporterAvatar"
                  />
                  <div>
                    <span>{{ item.createName }}</span>
                    <p class="mt5">
                      <span class="bg-grey-color tag fz10">{{
                        item.orderClassficationName
                      }}</span>
                      <span class="bg-grey-color tag fz10">{{
                        item.matterClassficationName
                      }}</span>
                      <span class="ft-red fz10 ml5">{{
                        item.matterPropertyName
                      }}</span>
                    </p>
                  </div>
                </div>
                <div class="info-right">
                  <span class="status-wait status">{{ item.statusName }}</span>
                </div>
              </div>
              <div class="order-info">
                <span class="fz12 mt15 block">{{ item.title }}</span>
                <div class="images" v-if="item.pictureUrl">
                  <img
                    v-for="img in item.pictureUrls"
                    :key="img"
                    :src="img"
                    class="mt10"
                    v-show="img"
                  />
                  <!-- <img
                    :src="item.pictureUrl"
                    :onerror="defaultImg"
                    class="mt10"
                  />
                  <img
                    :src="item.pictureUrl"
                    :onerror="defaultImg"
                    class="mt10"
                  /> -->
                </div>
                <p class="fz12 mt10">{{ item.des }}</p>
                <span class="block fz10 ft-light grey mt10">{{
                  formatDate(item.reportTime)
                }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="ant-spin-container" v-else>
          <div class="ant-list-empty-text">
            <div class="ant-empty ant-empty-normal">
              <div class="ant-empty-image">
                <svg
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse
                      fill="#F5F5F5"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g fillRule="nonzero" stroke="#D9D9D9">
                      <path
                        d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                      ></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        fill="#FAFAFA"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="ant-empty-description">暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content bg-block mt8">
        <div class="content-title border-bottom">
          <span class="fz14">最新动态</span>
        </div>
        <template v-if="newsList.length > 0">
          <div
            class="info-left flex-start mt15 newsS pb15"
            v-for="item in newsList"
            :key="item.id"
          >
            <div>
              <a-avatar
                :size="40"
                icon="user"
                class="mr10"
                :src="item.operateUserHeadImage"
              />
            </div>
            <div style="width: 100%">
              <div class="flex-between">
                <span>{{ item.operateUserName }}</span
                ><span class="fz10 ft-light">{{
                  formatDate(item.sendTime)
                }}</span>
              </div>
              <p class="mt10 fz12">
                {{ item.actionName.replace(/给/, "了") }}
                <span class="ft-org"
                  >{{ item.orderClassficationName }} |
                  {{ item.matterClassficationName }}</span
                >
                事项： <span class="ft-org">{{ item.title }}</span>
              </p>
            </div>
          </div>
        </template>
        <div class="ant-spin-container" v-else>
          <div class="ant-list-empty-text">
            <div class="ant-empty ant-empty-normal">
              <div class="ant-empty-image">
                <svg
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse
                      fill="#F5F5F5"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g fillRule="nonzero" stroke="#D9D9D9">
                      <path
                        d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                      ></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        fill="#FAFAFA"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="ant-empty-description">暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content bg-block mt8">
        <div class="content-title border-bottom">
          <span class="fz14">最佳实践</span>
          <router-link to="/case?nowTab=3" class="ft-grey fz12"
            >更多</router-link
          >
        </div>
        <template v-if="bestList.length > 0">
          <div
            class="content-block pb15"
            v-for="item in bestList"
            :key="item.id"
            @click="$router.push(`/case-detail?id=${item.id}`)"
          >
            <div class="user-info flex-between mt15">
              <div class="info-left flex-start">
                <div>
                  <span>{{ item.title }}</span>
                  <p class="mt5">
                    <span class="bg-grey-color tag fz10">{{
                      item.orderClassficationName
                    }}</span>
                    <span class="bg-grey-color tag fz10">{{
                      item.matterClassficationName
                    }}</span>
                    <span class="ft-red fz10 ml5">{{
                      item.matterPropertyName
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="info-right">
                <span
                  class="iconfont icon-hps-appzuijiashijian fz20 ft-org"
                ></span>
              </div>
            </div>
            <div class="order-info">
              <div class="images" v-if="item.pictureUrl">
                <img
                  v-for="img in item.pictureUrls"
                  :key="img"
                  :src="img"
                  :onerror="defaultImg"
                  class="mt10"
                  v-show="img"
                />
              </div>
              <p class="fz12 mt10">
                {{ item.des }}
              </p>
              <div class="flex-between">
                <span class="block fz10 ft-light grey mt10">{{
                  formatDate(item.setPracticeTime)
                }}</span>
                <span class="fz10 ft-light grey mt10">{{
                  item.orderBelongDept
                }}</span>
              </div>
            </div>
          </div>
        </template>
        <div class="ant-spin-container" v-else>
          <div class="ant-list-empty-text">
            <div class="ant-empty ant-empty-normal">
              <div class="ant-empty-image">
                <svg
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                    <ellipse
                      fill="#F5F5F5"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g fillRule="nonzero" stroke="#D9D9D9">
                      <path
                        d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                      ></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        fill="#FAFAFA"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p class="ant-empty-description">暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/footer/index";
import { row, col, Button, Avatar, Badge } from "ant-design-vue";
import {
  getWaitList,
  getMessges,
  getBestList,
  getOrderItemsNumber,
  getBestPracticeOrders,
  login,
} from "@/utils/api";
// import axios from "axios";
// let getOpenIdUrl =
//   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4157953fff8ba540&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";

export default {
  data() {
    return {
      pendingList: [],
      newsList: [],
      bestList: [],
      forHandleNumber: 0,
    };
  },
  components: {
    Footer,
    ARow: row,
    ACol: col,
    AButton: Button,
    AAvatar: Avatar,
    ABadge: Badge,
  },
  created() {
    // this.getOpenId();
    // this.login();
    this.getOrderItemsNumber();
    this.getWaitList();
    this.getNewsList();
    this.getBestPracticeOrders();
    this.timer = setInterval(() => {
      this.getOrderItemsNumber();
      this.getWaitList();
      this.getNewsList();
      this.getBestPracticeOrders();
    }, 60000);
  },
  // destroyed() {
  //   if (this.timer) {
  //     clearInterval(this.timer);
  //   }
  // },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // //获取openId
    // getOpenId() {
    //   axios.get(getOpenIdUrl).then((res) => {
    //     console.log(res);
    //   });
    // },
    //登录
    // async login() {
    //   let params = JSON.parse(localStorage.getItem("loginParams"));
    //   if (params) {
    //     const { resp_code, data } = await login(params);
    //     if (resp_code == 0) {
    //       let accessToken = data.accessToken;
    //       localStorage.setItem("accessToken", accessToken);
    //     }
    //   }
    // },
    //获取待处理列表
    async getWaitList() {
      const { data } = await getWaitList({ page: 1, limit: 2 });
      this.pendingList = data;
      // this.pendingList = [];
    },
    //获取最新动态列表
    async getNewsList() {
      let params = {
        page: 1,
        limit: 2,
      };
      const { data } = await getMessges(params);
      this.newsList = data;
      // this.newsList = [];
    },

    async getBestList() {
      const { data } = await getBestList();
    },
    sendTo(path, index) {
      this.$router.push({ path, query: { index } });
    },
    async getOrderItemsNumber() {
      const { data } = await getOrderItemsNumber();
      this.forHandleNumber = data.forHandleNumber;
    },
    //获取最新实践列表
    async getBestPracticeOrders() {
      const { data } = await getBestPracticeOrders();
      this.bestList = data;
      // this.bestList = [];
    },
  },
  computed: {
    defaultImg() {
      return `this.src="${require("@/assets/img/default.png")}"`;
    },
  },
};
</script>
<style lang="less" scoped>
.test {
  display: block;
  height: 4rem;
  line-height: 4rem;
}
.home {
  width: 100%;
  .top {
    text-align: center;
    img {
      max-width: 75rem;
      width: 100%;
      height: auto;
    }
    .menus {
      margin: 0 12px;
      background: #fff;
      position: relative;
      margin-top: -3.9rem;
      height: 9.1rem;
      border-radius: 8px;
      padding: 0 3.2rem;
      .iconfont {
        // font-size: 26px;
        img {
          width: 2.6rem;
        }
      }
    }
    .reprot-btn {
      border-radius: 4.4rem !important;
      width: 100%;
      height: 4.6rem;
    }
    .home-btn-box {
      margin-top: 2.3rem;
      padding: 0 8%;
    }
  }
}
.wait-list {
  li {
    border-bottom: 1px solid #dcdcdc;
  }
  li:last-child {
    border-bottom: 0;
  }
}
.main-content {
  padding: 0 1.4rem;
  .content-block {
    border-bottom: 1px solid #dcdcdc;
    &:last-child {
      border: 0;
    }
  }
  .content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.4rem;
    span {
      border-left: 3px solid #f39800;
      display: inline-block;
      height: 1.5rem;
      line-height: 1.5rem;
      padding-left: 8px;
    }
  }
  p {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    // font-weight: 300;
    color: #909399;
    line-height: 1.8rem;
  }
  .info-right {
    .iconfont {
      position: relative;
      top: -15px;
    }
  }
  .order-info {
    img {
      width: 10.4rem;
      height: 7.5rem;
      border-radius: 3px;
      display: block;
    }
  }
}
</style>